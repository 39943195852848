<template>
  <div class="navbar-header d-xl-block d-none">
    <ul class="nav navbar-nav">
      <li class="nav-item">
        <b-link class="navbar-brand" to="/">
          <span class="brand-logo">
            <svg
              preserveAspectRatio="xMidYMid meet"
              data-bbox="11.276 10.822 1245.924 622.078"
              viewBox="0 0 1257.2 632.9"
              xmlns="http://www.w3.org/2000/svg"
              data-type="ugc"
              role="img"
              aria-label="logo BR4 Distribuidora"
              style="width: 100px"
            >
              <g>
                <path
                  d="M191.5 190.1C67.4 264.2-5 343.8 14.4 397.9c29.4 81.9 258 74.3 510.4-16.9S958 149.4 928.6 67.6C899.7-12.8 679.1-6.9 432.3 79.5"
                  fill="none"
                  stroke="#fbfbfb"
                  stroke-width="18.08"
                  stroke-miterlimit="10"
                ></path>
                <path
                  d="M1247.7 324.1c5.1 0 9.5 3.8 9.5 8.9v90.2c0 5.7-4.4 9.5-9.5 9.5h-19.1v66.7c0 5.1-3.8 9.5-8.9 9.5h-112.4c-5.7 0-9.5-4.5-9.5-9.5v-66.7H930.1c-8.9 0-14-6.4-14-15.2v-74.3c0-6.4 1.3-10.8 4.5-16.5L1055.3 77c1.9-3.8 5.7-6.3 9.5-6.3h155c5.1 0 8.9 3.8 8.9 8.3v245.2l19-.1zm-149.9 0V200.2l-56.5 123.9h56.5z"
                  fill="#04a64b"
                ></path>
                <path
                  d="M284.8 550.2h30.3c25.6 0 43.2 17.5 43.2 40.4v.2c0 22.9-17.7 40.7-43.2 40.7h-30.3v-81.3zm30.3 68.3c17.1 0 28.2-11.5 28.2-27.4v-.2c0-15.9-11.1-27.7-28.2-27.7h-16v55.3h16z"
                  fill="#fafafa"
                ></path>
                <path
                  d="M368.3 550.2h14.3v81.3h-14.3v-81.3z"
                  fill="#fafafa"
                ></path>
                <path
                  d="m390.8 619.7 8.6-10.2c7.8 6.7 15.6 10.6 25.7 10.6 8.8 0 14.4-4.1 14.4-10.2v-.2c0-5.8-3.2-9-18.4-12.4-17.3-4.2-27.1-9.3-27.1-24.3v-.2c0-13.9 11.6-23.6 27.8-23.6 11.9 0 21.3 3.6 29.5 10.2l-7.7 10.8c-7.3-5.5-14.6-8.4-22.1-8.4-8.4 0-13.2 4.3-13.2 9.6v.2c0 6.3 3.7 9.1 19.3 12.8 17.2 4.2 26.1 10.3 26.1 23.8v.2c0 15.2-12 24.3-29 24.3-12.4 0-24.2-4.3-33.9-13z"
                  fill="#fafafa"
                ></path>
                <path
                  d="M480.7 563.4h-25.8v-13.2h66v13.2h-25.8v68.1h-14.4v-68.1z"
                  fill="#fafafa"
                ></path>
                <path
                  d="M527.8 550.2H564c10.2 0 18.2 3 23.5 8.1 4.3 4.4 6.7 10.5 6.7 17.5v.2c0 13.4-8 21.4-19.4 24.6l22 30.8H580l-20-28.3h-17.9v28.3h-14.3v-81.2zm35.2 40.3c10.2 0 16.7-5.3 16.7-13.6v-.2c0-8.7-6.3-13.5-16.8-13.5h-20.8v27.3H563z"
                  fill="#fafafa"
                ></path>
                <path
                  d="M604.5 550.2h14.3v81.3h-14.3v-81.3z"
                  fill="#fafafa"
                ></path>
                <path
                  d="M632.4 550.2h36.5c9.3 0 16.6 2.6 21.3 7.2 3.6 3.6 5.5 8 5.5 13.5v.2c0 9.8-5.6 15-11.6 18.1 9.5 3.2 16.2 8.7 16.2 19.8v.2c0 14.5-12 22.3-30.1 22.3h-37.6v-81.3h-.2zm33.6 34.3c9.2 0 15.3-3.6 15.3-11v-.2c0-6.4-5.1-10.3-14.3-10.3h-20.6v21.6l19.6-.1zm4.1 34.4c9.8 0 15.7-3.8 15.7-11.2v-.2c0-6.8-5.5-10.9-16.7-10.9h-22.7v22.3h23.7z"
                  fill="#fafafa"
                ></path>
                <path
                  d="M706.8 597v-46.8h14.3v46.2c0 15.1 7.8 23.2 20.6 23.2s20.5-7.7 20.5-22.7v-46.8h14.3v46.1c0 24.3-13.7 36.5-35 36.5s-34.7-12.1-34.7-35.7z"
                  fill="#fafafa"
                ></path>
                <path
                  d="M788.7 550.2H803v81.3h-14.3v-81.3z"
                  fill="#fafafa"
                ></path>
                <path
                  d="M816.6 550.2h30.3c25.6 0 43.2 17.5 43.2 40.4v.2c0 22.9-17.7 40.7-43.2 40.7h-30.3v-81.3zm30.3 68.3c17.1 0 28.2-11.5 28.2-27.4v-.2c0-15.9-11.2-27.7-28.2-27.7h-16v55.3h16z"
                  fill="#fafafa"
                ></path>
                <path
                  d="M895.7 591.1v-.2c0-22.9 17.7-42 42.6-42s42.4 18.9 42.4 41.8v.2c0 22.9-17.7 42-42.6 42s-42.4-18.9-42.4-41.8zm70.1 0v-.2c0-15.8-11.5-28.9-27.7-28.9s-27.4 12.9-27.4 28.7v.2c0 15.8 11.5 28.9 27.7 28.9s27.4-12.9 27.4-28.7z"
                  fill="#fafafa"
                ></path>
                <path
                  d="M989.9 550.2h36.2c10.2 0 18.2 3 23.5 8.1 4.3 4.4 6.7 10.5 6.7 17.5v.2c0 13.4-8 21.4-19.4 24.6l22 30.8h-16.8l-20-28.3h-17.9v28.3H990l-.1-81.2zm35.2 40.3c10.2 0 16.7-5.3 16.7-13.6v-.2c0-8.7-6.3-13.5-16.8-13.5h-20.8v27.3h20.9z"
                  fill="#fafafa"
                ></path>
                <path
                  d="M1094.6 549.6h13.2l35.8 81.9h-15.1l-8.2-19.6h-38.4l-8.4 19.6h-14.6l35.7-81.9zm20.4 49.6-14.1-32.5-13.9 32.5h28z"
                  fill="#fafafa"
                ></path>
                <path
                  d="M164.2 444v31.2c0 4.5 3.8 8.9 8.9 8.9h204.5c74.9 0 127.3-40.8 136.4-103.8C388 425.1 265.9 446 164.2 444z"
                  fill="#04a64b"
                ></path>
                <path
                  d="m913.8 473.9-5.2-9.2-88.8-157.2c43.8-22.2 71.1-67.3 71.1-118.1 0-9.9-.9-19.4-2.5-28.5-80.5 77.4-198 151.2-337.3 205.6v108.7c0 4.5 4.5 8.9 9.5 8.9h111.8c5.7 0 10.2-4.5 10.2-8.9V324.7c9.5-1.3 19 5.7 24.8 19.7L763.3 474c2.5 5.7 8.3 10.2 14.6 10.2h128.9c5.7-.1 10.1-4.5 7-10.3z"
                  fill="#04a64b"
                ></path>
                <path
                  d="M888.3 160.9c-12.7-70.2-73.4-115.1-160-115.1H560.6c-5.1 0-9.5 4.5-9.5 9.5v311.2c139.3-54.4 256.8-128.2 337.2-205.6zm-205.8-.7H720c21.6 0 36.2 13.3 36.2 33.7s-14.6 33.7-36.2 33.7h-37.5v-67.4z"
                  fill="#0080c9"
                ></path>
                <path
                  d="M514.1 380.3c.9-6.5 1.4-13.2 1.4-20.1 0-53.4-26.7-92.1-68.6-101.6 34.3-10.8 55.3-43.8 55.3-88.9 0-74.3-59.1-123.9-147.4-123.9H173.1c-5.1 0-8.9 5.1-8.9 9.5V444c101.7 2 223.8-18.9 349.9-63.7zm-219-224.6h36.2c21 0 34.9 13.3 34.9 33s-14 33-34.9 33h-36.2v-66zm0 149.9h48.3c22.2 0 37.5 14 37.5 34.3s-15.2 34.3-37.5 34.3h-48.3v-68.6z"
                  fill="#0080c9"
                ></path>
                <path
                  d="M14.4 397.9c29.4 81.9 258 74.3 510.4-16.9S958 149.4 928.6 67.6"
                  fill="none"
                  stroke="#fbfbfb"
                  stroke-width="18.08"
                  stroke-miterlimit="10"
                ></path>
              </g>
            </svg>
          </span>
          <!-- <b-img class="brand-text mb-0" :src="appLogoImageP2" alt="logo" /> -->
        </b-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { BLink, BImg } from "bootstrap-vue";
import { $themeConfig } from "@themeConfig";

export default {
  components: {
    BLink,
    BImg,
  },
  setup() {
    // App Name
    const { appName, appLogoImage } = $themeConfig.app;
    return {
      appName,
      appLogoImage,
    };
  },
};
</script>

<style>
</style>
